<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- TODO: copy -->
        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            SING-IN 👋🏻
          </p>
          <p class="mb-2">
            {{ showPinInput ? "Please put your email and pin." : "Please put your email to get a pin." }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              :disabled="disableEmail"
              label="Email"
              placeholder="email"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-if="showPinInput"
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Pin"
              placeholder="······"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              :rules="[validators.required, validators.pinLength]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <!-- <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
            </div> -->

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
            >
              {{ showPinInput ? "Login" : "Get pin" }}
            </v-btn>
          </v-form>
          <br>

          <v-alert
            v-if="errorMessages.length > 0"
            text
            color="error"
          >
            {{ errorMessages }}
          </v-alert>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator, pinLength } from '@core/utils/validation'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const showPinInput = ref(false)
    const disableEmail = ref(false)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref(null)
    const password = ref(null)
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      if (!showPinInput.value) {
        axios
          .post('/sign-in', { email: email.value })
          .then(() => {
            showPinInput.value = true
            disableEmail.value = true
            errorMessages.value = []
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to get pin!')
            console.log('error :>> ', error.response)
            errorMessages.value = error.response.data.message
          })
      } else {
        axios
          .post('/verify', { email: email.value, pin: password.value })
          .then(response => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userData')
            localStorage.removeItem('userAbility')

            // const { accessToken } = response.data.token

            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', response.data.token)

            // const { user } = response.data.currentUser

            // const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(response.data.currentUser.role)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', response.data.currentUser.role)

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            // delete user.ability

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(response.data.currentUser))

            errorMessages.value = []

            // On success redirect to home
            router.push({ name: 'administrators' })
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            // eslint-disable-next-line prefer-destructuring
            errorMessages.value = error.response.data.message
          })
      }
    }

    return {
      handleFormSubmit,
      showPinInput,
      disableEmail,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        pinLength,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
